import { makeStyles } from "@material-ui/core/styles";
import { withAuthUser } from "next-firebase-auth";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import errorPageStyle from "../assets/jss/nextjs-material-kit-pro/pages/errorPageStyles";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Layout from "../components/layout";
import { PageProps } from "../types/page";
import { getBuildVersion } from "../utilities/common";

const useStyles = makeStyles(errorPageStyle as any);

export async function getStaticProps(ctx) {
  const { locale } = ctx;
  const ssrConfig = await serverSideTranslations(locale, ["common", "aboutUs"]);
  const buildVersion = getBuildVersion();
  return {
    props: {
      ...ssrConfig,
      buildVersion,
    },
  };
}

function Custom404({ buildVersion }: PageProps) {
  const title = "無法找到頁面 Page Not Found :(";
  const classes = useStyles();
  return (
    <Layout
      title={`${title}  | Playwhat`}
      isHomepage
      buildVersion={buildVersion}
    >
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(/images/background/bg1.jpeg)",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.contentCenter}>
          <GridContainer>
            <GridItem md={12}>
              <h1 className={classes.title}>404</h1>
              <h2 className={classes.subTitle}>{title}</h2>
              <h4 className={classes.description}>
                <p>請往主頁尋找其他活動。</p>
                <p>
                  Please use our search engine to find your favourite events!
                </p>
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </Layout>
  );
}

export default withAuthUser<PageProps>()(Custom404);
